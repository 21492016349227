import moment from 'moment';
import { STORES } from '../constants';
import ConfigStore from './ConfigStore';
import PreviewStore from './PreviewStore';
import ApolloStore from './ApolloStore';
import RouterStore from './RouterStore';

const { STORE_CONFIG, STORE_ROUTER, STORE_ROOT, STORE_PREVIEW, STORE_APOLLO } =
  STORES;

// eslint-disable-next-line import/no-mutable-exports
export let rootStore = null;

moment.locale('en');
const locale = window.navigator.userLanguage || window.navigator.language;
moment.locale(locale);

class RootStore {
  constructor({ history }) {
    this.routerStore = new RouterStore(this, history);
    this.configStore = new ConfigStore(this);
    this.previewStore = new PreviewStore();
    this.apolloStore = new ApolloStore(this);
  }
}

export default function createStores({ history }) {
  rootStore = new RootStore({ history });
  return {
    [STORE_ROOT]: rootStore,
    [STORE_CONFIG]: rootStore.configStore,
    [STORE_ROUTER]: rootStore.routerStore,
    [STORE_PREVIEW]: rootStore.previewStore,
    [STORE_APOLLO]: rootStore.apolloStore,
  };
}
