import React from 'react';
import { observer } from 'mobx-react-lite';
import { Container, Row, Col } from '@administrate/piston-ux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { StickyContainer, Sticky } from 'react-sticky';

import { STORES } from '../../constants';
import inject from '../inject';
import WidgetList from '../WidgetList/WidgetList';
import WeblinkConfig from '../WeblinkConfig/WeblinkConfig';
import PreviewModal from '../PreviewModal/PreviewModal';
import PageHeader from '../../components/PageHeader';

const { STORE_PREVIEW, STORE_APOLLO } = STORES;

const Builder = ({
  [STORE_PREVIEW]: { show },
  [STORE_APOLLO]: { connectedPortalAddress },
  t,
}) => (
  <>
    <PageHeader
      title={t('builder:pageTitle')}
      subtitle={t('builder:pageConnectedSubTitle', { connectedPortalAddress })}
    />
    <StickyContainer>
      <div className="weblink-builder">
        <link
          rel="stylesheet"
          type="text/css"
          href={`https://${connectedPortalAddress}/static/css/main.css`}
        />
        <Container>
          <Row>
            <Col md={7} className="weblink-config">
              <Sticky
                topOffset={-20}
                disableCompensation={window.innerWidth < 992}
              >
                {({ style, isSticky }) => (
                  <div
                    style={
                      window.innerWidth >= 992
                        ? { ...style, marginTop: isSticky ? '20px' : null }
                        : {}
                    }
                  >
                    <WeblinkConfig />
                  </div>
                )}
              </Sticky>
            </Col>
            <Col md={5}>
              <WidgetList />
            </Col>
          </Row>
        </Container>
        {show && <PreviewModal />}
      </div>
    </StickyContainer>
  </>
);

Builder.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation()(
  inject(STORE_PREVIEW, STORE_APOLLO)(observer(Builder)),
);
