import React from 'react';
import { Observer, observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Checkbox, Fieldset, Select } from '@administrate/piston-ux';
import { FormConsumer } from '@administrate/piston-ux/lib/Form';

const eventListOrderFields = [
  { label: 'Name', value: 'name' },
  { label: 'Location Name', value: 'locationName' },
  { label: 'Start', value: 'start' },
  { label: 'Classroom Start', value: 'classroomStart' },
  { label: 'LMS Start', value: 'lmsStart' },
];
const eventListOrderDirection = [
  { label: 'Ascending', value: 'asc' },
  { label: 'Descending', value: 'desc' },
];

export const EVENT_LIST_COLUMN_INITIAL_FORM_VALUES = {
  eventListOrderField: null,
  eventListOrderDirection: null,
  showTitleColumn: false,
  showLocationColumn: true,
  showVenueColumn: false,
  showStartDateColumn: true,
  showEndDateColumn: false,
  showDateAndTimeColumn: false,
  showDurationColumn: true,
  showSessionsColumn: true,
  showTimeColumn: true,
  showPlacesRemainingColumn: true,
  showPriceColumn: true,
  showAddToCartColumn: true,
  showClassroomStartDateColumn: false,
  showClassroomDurationColumn: false,
  showClassroomTimeColumn: false,
  showLmsStartDateColumn: false,
  showLmsDurationColumn: false,
  showLmsTimeColumn: false,
};

const EventListColumnSelector = ({ t, onWidgetConfigChanged }) => (
  <>
    <FormConsumer>
      {({ values }) => (
        <Observer>
          {() => (
            <Fieldset
              captionType="label"
              legend={t('builder:defaultEventListOrder')}
            >
              <Select
                name="eventListOrderField"
                label={t('builder:eventListOrderField')}
                options={eventListOrderFields}
                onChange={selected =>
                  onWidgetConfigChanged(
                    'eventListOrder',
                    selected && values.eventListOrderDirection
                      ? {
                          field: selected,
                          direction: values.eventListOrderDirection,
                        }
                      : undefined,
                  )
                }
                valid={field =>
                  values.eventListOrderDirection && !field
                    ? t('builder:mustAlsoSelectAField')
                    : true
                }
              />
              <Select
                name="eventListOrderDirection"
                label={t('builder:eventListOrderDirection')}
                options={eventListOrderDirection}
                onChange={selected =>
                  onWidgetConfigChanged(
                    'eventListOrder',
                    selected && values.eventListOrderField
                      ? {
                          field: values.eventListOrderField,
                          direction: selected,
                        }
                      : undefined,
                  )
                }
                valid={direction =>
                  values.eventListOrderField && !direction
                    ? t('builder:mustAlsoSelectADirection')
                    : true
                }
              />
            </Fieldset>
          )}
        </Observer>
      )}
    </FormConsumer>
    <Fieldset captionType="label" legend={t('builder:eventListColumns')}>
      <Checkbox
        name="showTitleColumn"
        description={t('builder:showTitleColumnCheckbox')}
        onChange={value => onWidgetConfigChanged('showTitleColumn', value)}
      />
      <Checkbox
        name="showLocationColumn"
        description={t('builder:showLocationColumnCheckbox')}
        onChange={value => onWidgetConfigChanged('showLocationColumn', value)}
      />
      <Checkbox
        name="showVenueColumn"
        description={t('builder:showVenueColumnCheckbox')}
        onChange={value => onWidgetConfigChanged('showVenueColumn', value)}
      />
      <Checkbox
        name="showStartDateColumn"
        description={t('builder:showStartDateColumnCheckbox')}
        onChange={value => onWidgetConfigChanged('showStartDateColumn', value)}
      />
      <Checkbox
        name="showEndDateColumn"
        description={t('builder:showEndDateColumnCheckbox')}
        onChange={value => onWidgetConfigChanged('showEndDateColumn', value)}
      />
      <Checkbox
        name="showDateAndTimeColumn"
        description={t('builder:showDateAndTimeColumnCheckbox')}
        onChange={value =>
          onWidgetConfigChanged('showDateAndTimeColumn', value)
        }
      />
      <Checkbox
        name="showDurationColumn"
        description={t('builder:showDurationColumnCheckbox')}
        onChange={value => onWidgetConfigChanged('showDurationColumn', value)}
      />
      <Checkbox
        name="showSessionsColumn"
        description={t('builder:showSessionsColumnCheckbox')}
        onChange={value => onWidgetConfigChanged('showSessionsColumn', value)}
      />
      <Checkbox
        name="showTimeColumn"
        description={t('builder:showTimeColumnCheckbox')}
        onChange={value => onWidgetConfigChanged('showTimeColumn', value)}
      />
      <Checkbox
        name="showPlacesRemainingColumn"
        description={t('builder:showPlacesRemainingColumnCheckbox')}
        onChange={value =>
          onWidgetConfigChanged('showPlacesRemainingColumn', value)
        }
      />
      <Checkbox
        name="showPriceColumn"
        description={t('builder:showPriceColumnCheckbox')}
        onChange={value => onWidgetConfigChanged('showPriceColumn', value)}
      />
      <Checkbox
        name="showAddToCartColumn"
        description={t('builder:showAddToCartColumnCheckbox')}
        onChange={value => onWidgetConfigChanged('showAddToCartColumn', value)}
      />
      <Checkbox
        name="showClassroomStartDateColumn"
        description={t('builder:showClassroomStartDateColumnCheckbox')}
        onChange={value =>
          onWidgetConfigChanged('showClassroomStartDateColumn', value)
        }
      />
      <Checkbox
        name="showClassroomDurationColumn"
        description={t('builder:showClassroomDurationColumnCheckbox')}
        onChange={value =>
          onWidgetConfigChanged('showClassroomDurationColumn', value)
        }
      />
      <Checkbox
        name="showClassroomTimeColumn"
        description={t('builder:showClassroomTimeColumnCheckbox')}
        onChange={value =>
          onWidgetConfigChanged('showClassroomTimeColumn', value)
        }
      />
      <Checkbox
        name="showLmsStartDateColumn"
        description={t('builder:showLmsStartDateColumnCheckbox')}
        onChange={value =>
          onWidgetConfigChanged('showLmsStartDateColumn', value)
        }
      />
      <Checkbox
        name="showLmsDurationColumn"
        description={t('builder:showLmsDurationColumnCheckbox')}
        onChange={value =>
          onWidgetConfigChanged('showLmsDurationColumn', value)
        }
      />
      <Checkbox
        name="showLmsTimeColumn"
        description={t('builder:showLmsTimeColumnCheckbox')}
        onChange={value => onWidgetConfigChanged('showLmsTimeColumn', value)}
      />
    </Fieldset>
  </>
);

EventListColumnSelector.propTypes = {
  t: PropTypes.func.isRequired,
  onWidgetConfigChanged: PropTypes.func.isRequired,
};

export default withTranslation()(observer(EventListColumnSelector));
