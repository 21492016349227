import { Observer, observer } from 'mobx-react-lite';
import { PropTypes as MobXPropTypes } from 'mobx-react';
import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import {
  useTypedFormValues,
  Form,
  Fieldset,
  Input,
  Select,
  Checkbox,
  Tooltip,
} from '@administrate/piston-ux';
import { FormConsumer } from '@administrate/piston-ux/lib/Form';
import LocationPicker from '../pickers/LocationPicker';
import NumberWithOperationPicker from '../pickers/NumberWithOperationPicker';
import CategoryPicker from '../pickers/CategoryPicker';
import LearningTagPicker from '../pickers/LearningTagPicker';
import DateRangePicker from '../pickers/DateRangePicker';
import { arrayToIdList } from '../pickers/pickerHelpers';
import CustomFieldFilterConfigForm from './customFieldFilters/CustomFieldFilterConfigForm';
import EventListFilterSelector, {
  EVENT_LIST_FILTER_INITIAL_FORM_VALUES,
} from './shared/EventListFilterSelector';
import EventListColumnSelector, {
  EVENT_LIST_COLUMN_INITIAL_FORM_VALUES,
} from './shared/EventListColumnSelector';

const CatalogueConfigForm = ({ t, widget, onWidgetConfigChanged }) => {
  const values = useTypedFormValues({
    categories: [],
    locations: [],
    date: null,
    learningTags: [],
    placesRemaining: {
      operation: null,
      numberValue: null,
    },
    includeSoldOut: false,
    customFieldFilters: [],
    courseNameSearchText: '',
    type: null,
    pageSize: 6,
    pagerType: 'loadMore',
    showDescription: false,
    showCategory: true,
    showFeaturedLabel: true,
    showPrice: true,
    ...EVENT_LIST_FILTER_INITIAL_FORM_VALUES,
    ...EVENT_LIST_COLUMN_INITIAL_FORM_VALUES,
  });

  const addNewCustomFieldFilter = () => {
    values.customFieldFilters.push({
      customFieldFilter: null,
      value: null,
    });
  };

  const removeCustomFieldFilter = index => {
    values.customFieldFilters.splice(index, 1);

    const updatedCustomFieldFilters = [
      ...(widget.props.customFieldFilters ?? []),
    ];
    updatedCustomFieldFilters.splice(index, 1);

    onWidgetConfigChanged(
      'customFieldFilters',
      updatedCustomFieldFilters.length ? updatedCustomFieldFilters : undefined,
    );
  };

  return (
    <Form values={values}>
      <CategoryPicker
        name="categories"
        multiple
        onChange={value =>
          onWidgetConfigChanged('categories', arrayToIdList(value))
        }
      />
      <LocationPicker
        name="locations"
        multiple
        onChange={value =>
          onWidgetConfigChanged('locations', arrayToIdList(value))
        }
      />
      <DateRangePicker
        onFromDateChange={value => onWidgetConfigChanged('fromDate', value)}
        onToDateChange={value => onWidgetConfigChanged('toDate', value)}
      />
      <LearningTagPicker
        name="learningTags"
        onChange={value =>
          onWidgetConfigChanged('learningTags', arrayToIdList(value))
        }
      />
      <PlacesRemainingConfig
        t={t}
        onRemainingPlacesConfigChanged={placesRemaining =>
          onWidgetConfigChanged('placesRemaining', placesRemaining)
        }
      />
      <Input
        name="courseNameSearchText"
        label={t('builder:nameSearchLabel')}
        onChange={value =>
          onWidgetConfigChanged(
            'courseNameSearchText',
            value === '' ? undefined : value,
          )
        }
      />
      <Select
        name="type"
        label={t('builder:type')}
        isClearable={false}
        options={[
          { label: 'Courses', value: 'course' },
          { label: 'Paths', value: 'path' },
          { label: 'All', value: null },
        ]}
        onChange={selected =>
          onWidgetConfigChanged(
            'catalogueType',
            !selected ? undefined : selected,
          )
        }
      />
      <Input
        name="pageSize"
        label={t('builder:pageSize')}
        min={1}
        max={50}
        type="number"
        onChange={newValue => {
          const value =
            newValue && newValue > 0 && newValue <= 50
              ? Number.parseInt(newValue, 10)
              : undefined;
          onWidgetConfigChanged('pageSize', value);
        }}
      />
      <Select
        name="pagerType"
        label={t('builder:pagerType')}
        isClearable={false}
        options={[
          { label: 'Load More', value: 'loadMore' },
          { label: 'Full Pager', value: 'full' },
        ]}
        onChange={selected => onWidgetConfigChanged('pagerType', selected)}
      />
      <Fieldset captionType="label">
        <CustomFieldFilterConfigForm
          onAddClick={addNewCustomFieldFilter}
          onRemoveClick={removeCustomFieldFilter}
          onCustomFieldFilterChange={(index, value) => {
            const updatedCustomFieldFilters = [
              ...(widget.props.customFieldFilters ?? []),
            ];
            updatedCustomFieldFilters[index] = value;

            onWidgetConfigChanged(
              'customFieldFilters',
              updatedCustomFieldFilters,
            );
          }}
        />
      </Fieldset>
      <Fieldset captionType="label" legend={t('builder:catalogueCardOptions')}>
        <Checkbox
          name="showDescription"
          description={t('builder:showDescription')}
          onChange={value =>
            onWidgetConfigChanged('catalogueCardOptions', {
              ...widget.props.catalogueCardOptions,
              showDescription: value,
            })
          }
        />
        <Checkbox
          name="showCategory"
          description={t('builder:showCategory')}
          onChange={value =>
            onWidgetConfigChanged('catalogueCardOptions', {
              ...widget.props.catalogueCardOptions,
              showCategory: value,
            })
          }
        />
        <Checkbox
          name="showFeaturedLabel"
          description={t('builder:showFeaturedLabel')}
          onChange={value =>
            onWidgetConfigChanged('catalogueCardOptions', {
              ...widget.props.catalogueCardOptions,
              showFeaturedLabel: value,
            })
          }
        />
        <Tooltip content={t('builder:showPriceTooltip')} placement="right">
          <Checkbox
            name="showPrice"
            description={t('builder:showPrice')}
            onChange={value =>
              onWidgetConfigChanged('catalogueCardOptions', {
                ...widget.props.catalogueCardOptions,
                showPrice: value,
              })
            }
          />
        </Tooltip>
      </Fieldset>
      <EventListFilterSelector onWidgetConfigChanged={onWidgetConfigChanged} />
      <EventListColumnSelector onWidgetConfigChanged={onWidgetConfigChanged} />
    </Form>
  );
};

CatalogueConfigForm.propTypes = {
  t: PropTypes.func.isRequired,
  widget: PropTypes.shape({
    props: PropTypes.shape({
      catalogueCardOptions: PropTypes.shape({
        showDescription: PropTypes.bool.isRequired,
        showCategory: PropTypes.bool.isRequired,
        showFeaturedLabel: PropTypes.bool.isRequired,
        showPrice: PropTypes.bool.isRequired,
      }),
      placesRemaining: PropTypes.shape({
        operation: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
      }),
      customFieldFilters: MobXPropTypes.observableArrayOf(
        MobXPropTypes.observableObject,
      ),
    }).isRequired,
  }).isRequired,
  onWidgetConfigChanged: PropTypes.func.isRequired,
};

const buildPlacesRemainingConfig = (operation, numberValue, includeSoldOut) => {
  const config = [];

  if (!operation || !numberValue) {
    return undefined;
  }

  config.push({
    operation,
    value: Number.parseInt(numberValue, 10),
  });

  if (!includeSoldOut) {
    config.push({
      operation: 'gt',
      value: 0,
    });
  }

  return config;
};

const PlacesRemainingConfig = ({ t, onRemainingPlacesConfigChanged }) => (
  <FormConsumer>
    {({ values: formValues }) => (
      <Observer>
        {() => (
          <Fieldset captionType="label" legend={t('builder:placesRemaining')}>
            <NumberWithOperationPicker
              name="placesRemaining"
              id="placesRemaining"
              onOperationChange={operation => {
                const newValue = buildPlacesRemainingConfig(
                  operation,
                  formValues.placesRemaining.numberValue,
                  formValues.includeSoldOut,
                );

                onRemainingPlacesConfigChanged(newValue);
              }}
              onNumberValueChange={numberValue => {
                const newValue = buildPlacesRemainingConfig(
                  formValues.placesRemaining.operation,
                  numberValue,
                  formValues.includeSoldOut,
                );

                onRemainingPlacesConfigChanged(newValue);
              }}
            />
            {formValues.placesRemaining.operation &&
              formValues.placesRemaining.numberValue && (
                <Checkbox
                  name="includeSoldOut"
                  description={t('builder:includeSoldOut')}
                  onChange={includeSoldOut => {
                    const newValue = buildPlacesRemainingConfig(
                      formValues.placesRemaining.operation,
                      formValues.placesRemaining.numberValue,
                      includeSoldOut,
                    );

                    onRemainingPlacesConfigChanged(newValue);
                  }}
                />
              )}
          </Fieldset>
        )}
      </Observer>
    )}
  </FormConsumer>
);

PlacesRemainingConfig.propTypes = {
  t: PropTypes.func.isRequired,
  onRemainingPlacesConfigChanged: PropTypes.func.isRequired,
};

export default withTranslation()(observer(CatalogueConfigForm));
