import { observer } from 'mobx-react-lite';
import React from 'react';
import PropTypes from 'prop-types';
import { useTypedFormValues, Form } from '@administrate/piston-ux';
import LearningPathPicker from '../pickers/LearningPathPicker';

const PathDetailsConfigForm = ({ onWidgetConfigChanged }) => {
  const values = useTypedFormValues({
    learningPath: null,
  });

  return (
    <Form values={values}>
      <LearningPathPicker
        name="learningPath"
        required
        valid={v => !!v}
        onChange={learningPath => onWidgetConfigChanged('id', learningPath.id)}
      />
    </Form>
  );
};

PathDetailsConfigForm.propTypes = {
  onWidgetConfigChanged: PropTypes.func.isRequired,
};

export default observer(PathDetailsConfigForm);
