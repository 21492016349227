import merge from 'lodash/merge';

const envConfig = {
  nodeEnv: process.env.NODE_ENV,
  get isDev() {
    return this.nodeEnv === 'development';
  },
};

const source = envConfig.isDev ? process.env : window;

const config = {
  portalAuthURL: source.REACT_APP_PORTAL_AUTH_URL,
  graphQLURL: source.REACT_APP_GRAPHQL_API_URL,
};

export default merge(config, envConfig);
