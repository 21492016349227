import { observer } from 'mobx-react-lite';
import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { DateRangeInput } from '@administrate/piston-ux';

const DateRangePicker = ({ t, onFromDateChange, onToDateChange }) => (
  <DateRangeInput
    name="date"
    label={t('builder:startDateRange')}
    onChange={({ start, end }) => {
      const updatedFromDate = start
        ? moment(start).utc(true).startOf('day').toISOString()
        : undefined;
      const updatedToDate = end
        ? moment(end).utc(true).endOf('day').toISOString()
        : undefined;

      onFromDateChange(updatedFromDate);
      onToDateChange(updatedToDate);
    }}
  />
);

DateRangePicker.propTypes = {
  t: PropTypes.func.isRequired,
  onFromDateChange: PropTypes.func.isRequired,
  onToDateChange: PropTypes.func.isRequired,
};

export default withTranslation()(observer(DateRangePicker));
