import React from 'react';
import PropTypes from 'prop-types';
import { useApolloClient, gql } from '@apollo/client';
import { Typeahead } from '@administrate/piston-ux';
import { withTranslation } from 'react-i18next';
import { extractNodes } from './pickerHelpers';

const GET_COURSES = gql`
  query getCourses($filters: [CourseFieldFilter!]!, $order: CourseFieldOrder!) {
    courses(filters: $filters, order: $order) {
      edges {
        node {
          id
          code
          name
        }
      }
    }
  }
`;

const CoursePicker = ({ t, name, required, valid, onChange }) => {
  const client = useApolloClient();

  const loadOptions = async inputValue => {
    const { data } = await client.query({
      query: GET_COURSES,
      variables: {
        filters: [
          {
            field: 'name',
            operation: 'like',
            value: `%${inputValue}%`,
          },
        ],
        order: {
          field: 'name',
          direction: 'asc',
        },
      },
    });

    return extractNodes(data?.courses);
  };

  return (
    <Typeahead
      name={name}
      label={t('builder:course')}
      required={required}
      loadOptions={loadOptions}
      formatter={course => course.name}
      staticFormatter={course => course.name}
      valid={valid}
      onChange={onChange}
      isClearable={!required}
    />
  );
};

CoursePicker.defaultProps = {
  required: false,
  valid: undefined,
};

CoursePicker.propTypes = {
  t: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  valid: PropTypes.func,
};

export default withTranslation()(CoursePicker);
