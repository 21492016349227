import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import en from './locales/en/translation.json';

export function configure(instance) {
  if (!instance) {
    i18n.use(LanguageDetector).use(initReactI18next).init({
      fallbackLng: 'en',
    });
    i18n.addResources('en', 'builder', en);

    return i18n;
  }

  instance.addResourceBundle('en', 'builder', en);

  return instance;
}
