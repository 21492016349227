import { observer } from 'mobx-react-lite';
import React from 'react';
import PropTypes from 'prop-types';
import { useTypedFormValues, Form } from '@administrate/piston-ux';
import CatalogueProductPicker from '../pickers/CatalogueProductPicker';

const TrainingRequestConfigForm = ({ onWidgetConfigChanged }) => {
  const values = useTypedFormValues({
    catalogueProduct: null,
  });

  return (
    <Form values={values}>
      <CatalogueProductPicker
        name="catalogueProduct"
        onChange={catalogueProduct =>
          onWidgetConfigChanged(
            'interestId',
            catalogueProduct ? catalogueProduct.id : undefined,
          )
        }
      />
    </Form>
  );
};

TrainingRequestConfigForm.propTypes = {
  onWidgetConfigChanged: PropTypes.func.isRequired,
};

export default observer(TrainingRequestConfigForm);
