import { observer } from 'mobx-react-lite';
import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import {
  useTypedFormValues,
  Form,
  Checkbox,
  Input,
} from '@administrate/piston-ux';

const BasketConfigForm = ({ t, onWidgetConfigChanged }) => {
  const values = useTypedFormValues({
    showBasketPopover: false,
    cartUrl: '',
  });

  return (
    <Form values={values}>
      <Checkbox
        name="showBasketPopover"
        description={t('builder:showBasketPopover')}
        onChange={value => onWidgetConfigChanged('showBasketPopover', value)}
      />
      <Input
        name="cartUrl"
        label={t('builder:cartUrl')}
        helpMessage={t('builder:cartUrlTooltip')}
        onChange={value =>
          onWidgetConfigChanged('cartUrl', value === '' ? undefined : value)
        }
      />
    </Form>
  );
};

BasketConfigForm.propTypes = {
  t: PropTypes.func.isRequired,
  onWidgetConfigChanged: PropTypes.func.isRequired,
};

export default withTranslation()(observer(BasketConfigForm));
