import React from 'react';
import PropTypes from 'prop-types';
import { useApolloClient, gql } from '@apollo/client';
import { Typeahead } from '@administrate/piston-ux';
import { withTranslation } from 'react-i18next';
import { extractNodes } from './pickerHelpers';

const GET_CATALOGUE_PRODUCTS = gql`
  query getCatalogueProducts(
    $filters: [CatalogueFieldFilter!]!
    $order: CatalogueFieldOrder!
  ) {
    catalogue(filters: $filters, order: $order) {
      edges {
        node {
          ... on Course {
            id
            name
          }
          ... on LearningPath {
            id
            name
          }
        }
      }
    }
  }
`;

const CatalogueProductPicker = ({ t, name, required, valid, onChange }) => {
  const client = useApolloClient();

  const loadOptions = async inputValue => {
    const { data } = await client.query({
      query: GET_CATALOGUE_PRODUCTS,
      variables: {
        filters: [
          {
            field: 'name',
            operation: 'like',
            value: `%${inputValue}%`,
          },
        ],
        order: {
          field: 'name',
          direction: 'asc',
        },
      },
    });

    return extractNodes(data?.catalogue);
  };

  return (
    <Typeahead
      name={name}
      label={t('builder:catalogueProduct')}
      required={required}
      loadOptions={loadOptions}
      formatter={product => product.name}
      staticFormatter={product => product.name}
      valid={valid}
      onChange={onChange}
      isClearable={!required}
    />
  );
};

CatalogueProductPicker.defaultProps = {
  required: false,
  valid: undefined,
};

CatalogueProductPicker.propTypes = {
  t: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  valid: PropTypes.func,
};

export default withTranslation()(CatalogueProductPicker);
