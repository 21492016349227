import React, { createContext, Suspense } from 'react';
import PropTypes from 'prop-types';
import { I18nextProvider } from 'react-i18next';

import { configure as configureI18n } from './i18n';

export const STORE = createContext();

export const Provider = ({ stores, i18n, children }) => {
  const StoreProvider = STORE.Provider;

  return (
    <Suspense fallback="Loading...">
      <I18nextProvider i18n={configureI18n(i18n)}>
        <StoreProvider value={stores}>{children}</StoreProvider>
      </I18nextProvider>
    </Suspense>
  );
};

Provider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  stores: PropTypes.shape({}).isRequired,
  i18n: PropTypes.shape({}),
};

Provider.defaultProps = {
  i18n: null,
};
