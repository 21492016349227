import { Observer, observer } from 'mobx-react-lite';
import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Button, Flex, View } from '@administrate/piston-ux';
import { FormArray, FormConsumer } from '@administrate/piston-ux/lib/Form';
import CustomFieldFilterPicker from '../../pickers/CustomFieldFilterPicker';
import CustomFieldFilterValuePicker, {
  getCustomFieldConfigurationFormatter,
  getDefaultValueForCustomFieldFilterType,
} from '../../pickers/CustomFieldFilterValuePicker';

const CustomFieldFilterConfigForm = ({
  t,
  onAddClick,
  onRemoveClick,
  onCustomFieldFilterChange,
}) => {
  const resetCustomFieldFilterValue = (formValues, type) => {
    const defaultValue = getDefaultValueForCustomFieldFilterType(type);
    // eslint-disable-next-line no-param-reassign
    formValues.value = defaultValue;
  };

  return (
    <>
      <FormArray name="customFieldFilters">
        <FormConsumer>
          {({ values, arrayIndex }) => (
            <Observer>
              {() => (
                <Flex gap="default">
                  <View flex={1}>
                    <CustomFieldFilterPicker
                      name="customFieldFilter"
                      onChange={newCustomFieldFilter => {
                        resetCustomFieldFilterValue(
                          values,
                          newCustomFieldFilter.type,
                        );
                        const customFieldConfigurationFormatter =
                          getCustomFieldConfigurationFormatter(
                            newCustomFieldFilter.type,
                          );
                        onCustomFieldFilterChange(
                          arrayIndex,
                          customFieldConfigurationFormatter(
                            newCustomFieldFilter.id,
                            values.value,
                          ),
                        );
                      }}
                    />
                  </View>
                  <View flex={1}>
                    <CustomFieldFilterValuePicker
                      customFieldFilter={values.customFieldFilter}
                      name="value"
                      onChange={value =>
                        onCustomFieldFilterChange(arrayIndex, value)
                      }
                    />
                  </View>
                  <View
                    flex={0}
                    spaceTop="auto"
                    spaceBottom="auto"
                    paddingTop="medium"
                  >
                    <Button
                      className="glyphicon-pro glyphicon-pro-bin"
                      onClick={() => onRemoveClick(arrayIndex)}
                    />
                  </View>
                </Flex>
              )}
            </Observer>
          )}
        </FormConsumer>
      </FormArray>
      <Button
        block
        label={t('builder:addCustomFieldfilter')}
        onClick={onAddClick}
      />
    </>
  );
};

CustomFieldFilterConfigForm.propTypes = {
  t: PropTypes.func.isRequired,
  onAddClick: PropTypes.func.isRequired,
  onRemoveClick: PropTypes.func.isRequired,
  onCustomFieldFilterChange: PropTypes.func.isRequired,
};

export default withTranslation()(observer(CustomFieldFilterConfigForm));
