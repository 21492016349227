import { observable, decorate, runInAction, action } from 'mobx';
import {
  createApolloClient,
  _bundleConfig as config,
} from '@administrate/weblink';

import BaseStore from './baseStore';
import createWeblinkStores from '../weblinkProvider';
import builderConfig from '../config';
import queries from '../queries';

const { graphQLURL, portalAuthURL } = builderConfig;
Object.assign(config, { SFAPIURL: graphQLURL });
Object.assign(config, { portalAuthURL });

export default class ApolloStore extends BaseStore {
  constructor(rootStore) {
    super(rootStore);
    runInAction(() => {
      this.apolloClient = null;
      this.connectedPortalAddress = null;
      this.connectionRefused = false;
      this.connecting = false;
    });
  }

  changeConnecting = action(value => {
    this.connecting = value;
  });

  changeConnectionRefused = action(value => {
    this.connectionRefused = value;
    if (value) {
      this.connectedPortalAddress = null;
      this.apolloClient = null;
    }
  });

  connectApolloClient = async () => {
    this.changeConnecting(true);

    const { configStore, routerStore } = this.rootStore;
    configStore.changePortalAddress(
      configStore.portalAddress.replace(/https?:\/\//, '').split('/')[0],
    );

    try {
      this.createClient(configStore.portalAddress);

      this.changeConnectionRefused(false);

      await this.testConnection();

      if (configStore.rememberPortal) {
        window.localStorage.setItem(
          'weblink:portalAddress',
          configStore.portalAddress,
        );
      } else {
        window.localStorage.removeItem('weblink:portalAddress');
      }
      routerStore.navigateToBuilder();
    } catch (err) {
      this.changeConnectionRefused(true);
    }

    this.changeConnecting(false);
  };

  createClient = action(portalAddress => {
    if (
      this.connectedPortalAddress !== portalAddress ||
      this.apolloClient === null
    ) {
      window.localStorage.removeItem(`weblink:${portalAddress}:portalToken`);
      Object.assign(config, { portalAuthDomain: portalAddress });
      this.apolloClient = createApolloClient({
        SFAPIURL: graphQLURL,
        portalAuthURL,
        portalAuthDomain: portalAddress,
      });
      createWeblinkStores({
        apolloClient: this.apolloClient,
        portalAuthDomain: portalAddress,
      });
      this.connectedPortalAddress = portalAddress;
    }
  });

  testConnection = action(async () => {
    const { data: helloResponse } = await this.apolloClient.query({
      query: queries.hello,
    });

    if (helloResponse.hello !== 'Hello') {
      throw new Error();
    }
  });
}

decorate(ApolloStore, {
  connectionRefused: observable,
  connecting: observable,
  connectedPortalAddress: observable,
  apolloClient: observable,
});
