/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';

import createStores from './stores';
import './index.css';
import App from './App';

const history = createBrowserHistory();
const stores = createStores({ history });

ReactDOM.render(
  <App stores={stores} history={history} />,
  document.getElementById('root'),
);
