import { gql } from '@apollo/client';

const hello = gql`
  query hello {
    hello
  }
`;

export default {
  hello,
};
