import React from 'react';
import { observer } from 'mobx-react-lite';
import {
  ContextProvider,
  Cart,
  Debug,
  CourseDetails,
  PathDetails,
  EventList,
  EmbedBasket,
  EmbedNavigation,
  EmbedCategory,
  SearchBar,
  CategoryDropdown,
  CallToActionCard,
  TrainingRequest,
} from '@administrate/weblink';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Modal, Prompt, UtilityStyle } from '@administrate/piston-ux';
import { toJS } from 'mobx';

import { STORES } from '../../constants';
import inject from '../inject';
import { stores } from '../../weblinkProvider';
import builderConfig from '../../config';

const CourseDetailsWrapper = ({ ...props }) => (
  <CourseDetails {...props} showBreadcrumb={false} />
);

const PathDetailsWrapper = ({ ...props }) => (
  <PathDetails {...props} showBreadcrumb={false} />
);

const components = {
  Cart,
  Debug,
  CourseDetails: CourseDetailsWrapper,
  PathDetails: PathDetailsWrapper,
  EventList,
  Basket: EmbedBasket,
  Catalogue: EmbedNavigation,
  Category: EmbedCategory,
  SearchBar,
  CategoryDropdown,
  CallToActionCard,
  TrainingRequest,
};

const { STORE_PREVIEW, STORE_CONFIG, STORE_APOLLO } = STORES;

const PreviewModal = ({
  [STORE_PREVIEW]: {
    closeModal,
    widget: { name, id, props },
    show,
  },
  [STORE_CONFIG]: { timezone },
  [STORE_APOLLO]: { apolloClient },
  t,
}) => {
  const ComponentToRender = components[name];

  const rawProps = toJS(props);

  return (
    <Modal
      title={`${t('builder:preview')} ${name} #${id}`}
      show={show}
      onDone={closeModal}
      showCancelButton={false}
      className="previewModal"
    >
      <ContextProvider
        stores={stores}
        timezone={timezone}
        apolloClient={apolloClient}
        weblinkApiConfiguration={{
          uri: builderConfig.graphQLURL,
          portalAuthDomain: builderConfig.portalAuthURL,
        }}
      >
        <ComponentToRender {...rawProps} />
        {(name === 'SearchBar' || name === 'CategoryDropdown') && (
          <div style={{ marginTop: '16px' }}>
            <Prompt
              type="info"
              message={t('builder:extraCataloguePreviewInfo')}
              styleOptions={[UtilityStyle.BottomSpaceMedium]}
            />
            <EmbedNavigation />
          </div>
        )}
      </ContextProvider>
    </Modal>
  );
};

PreviewModal.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation()(
  inject(STORE_PREVIEW, STORE_CONFIG, STORE_APOLLO)(observer(PreviewModal)),
);
