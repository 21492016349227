import { observer } from 'mobx-react-lite';
import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { useTypedFormValues, Form, Checkbox } from '@administrate/piston-ux';
import LocationPicker from '../pickers/LocationPicker';
import CategoryPicker from '../pickers/CategoryPicker';
import DateRangePicker from '../pickers/DateRangePicker';
import { arrayToIdList } from '../pickers/pickerHelpers';
import EventListFilterSelector, {
  EVENT_LIST_FILTER_INITIAL_FORM_VALUES,
} from './shared/EventListFilterSelector';
import EventListColumnSelector, {
  EVENT_LIST_COLUMN_INITIAL_FORM_VALUES,
} from './shared/EventListColumnSelector';
import CoursePicker from '../pickers/CoursePicker';

const EventListConfigForm = ({ t, onWidgetConfigChanged }) => {
  const values = useTypedFormValues({
    course: null,
    locations: [],
    date: null,
    category: null,
    showCourseFilter: true,
    showCategoryFilter: true,
    ...EVENT_LIST_FILTER_INITIAL_FORM_VALUES,
    ...EVENT_LIST_COLUMN_INITIAL_FORM_VALUES,
    showTitleColumn: true,
  });

  return (
    <Form values={values}>
      <CoursePicker
        name="course"
        onChange={course =>
          onWidgetConfigChanged('code', course ? course.code : undefined)
        }
      />
      <LocationPicker
        name="locations"
        multiple
        onChange={value =>
          onWidgetConfigChanged('locations', arrayToIdList(value))
        }
      />
      <DateRangePicker
        onFromDateChange={value => onWidgetConfigChanged('fromDate', value)}
        onToDateChange={value => onWidgetConfigChanged('toDate', value)}
      />
      <CategoryPicker
        name="category"
        onChange={value => onWidgetConfigChanged('category', value?.id ?? null)}
      />
      <EventListFilterSelector onWidgetConfigChanged={onWidgetConfigChanged}>
        <Checkbox
          name="showCourseFilter"
          description={t('builder:showCourseFilterCheckbox')}
          onChange={value => onWidgetConfigChanged('showCourseFilter', value)}
        />
        <Checkbox
          name="showCategoryFilter"
          description={t('builder:showCategoryFilterCheckbox')}
          onChange={value => onWidgetConfigChanged('showCategoryFilter', value)}
        />
      </EventListFilterSelector>
      <EventListColumnSelector onWidgetConfigChanged={onWidgetConfigChanged} />
    </Form>
  );
};

EventListConfigForm.propTypes = {
  t: PropTypes.func.isRequired,
  onWidgetConfigChanged: PropTypes.func.isRequired,
};

export default withTranslation()(observer(EventListConfigForm));
