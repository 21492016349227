import React from 'react';
import PropTypes from 'prop-types';
import { useApolloClient, gql } from '@apollo/client';
import { Typeahead } from '@administrate/piston-ux';
import { withTranslation } from 'react-i18next';
import { extractNodes } from './pickerHelpers';

const GET_CUSTOM_FIELD_FILTERS = gql`
  query customFieldFilters {
    store {
      customFieldFilters {
        edges {
          node {
            id
            label
            type
            mappedCustomFields {
              options {
                label
                value
              }
            }
          }
        }
      }
    }
  }
`;

const CustomFieldFilterPicker = ({ t, name, onChange }) => {
  const client = useApolloClient();

  const loadOptions = async () => {
    const { data } = await client.query({
      query: GET_CUSTOM_FIELD_FILTERS,
    });

    return extractNodes(data?.store?.customFieldFilters);
  };

  return (
    <Typeahead
      name={name}
      isClearable={false}
      label={t('builder:customFieldFilter')}
      loadOptions={loadOptions}
      formatter={customFieldFilter => customFieldFilter.label}
      staticFormatter={customFieldFilter => customFieldFilter.label}
      onChange={onChange}
    />
  );
};

CustomFieldFilterPicker.propTypes = {
  t: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default withTranslation()(CustomFieldFilterPicker);
