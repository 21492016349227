import React from 'react';
import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';
import {
  Alert,
  Card,
  Checkbox,
  Form,
  Input,
  LoadingBar,
  SectionHeader,
  Submit,
  useTypedFormValues,
} from '@administrate/piston-ux';
import { withTranslation } from 'react-i18next';

import { STORES } from '../../constants';
import inject from '../inject';
import PageHeader from '../../components/PageHeader';

const { STORE_CONFIG, STORE_APOLLO } = STORES;

const PortalSetup = ({
  [STORE_CONFIG]: {
    portalAddress,
    rememberPortal,
    changeRememberPortal,
    changePortalAddress,
  },
  [STORE_APOLLO]: { connectApolloClient, connecting, connectionRefused },
  t,
}) => {
  const formValues = useTypedFormValues({
    portalAddress,
    rememberPortal,
  });

  return (
    <>
      <PageHeader title={t('builder:pageTitle')} />
      <LoadingBar overPage isLoading={connecting} />
      <div className="portal-setup">
        {connectionRefused && (
          <Alert message={t('builder:refusedConnectionBody')} type="error" />
        )}
        <Card>
          <SectionHeader title={t('builder:connectToAWeblinkPortal')} />
          <Form values={formValues} onSubmit={connectApolloClient}>
            <Input
              type="text"
              label={t('builder:portalAddress')}
              name="portalAddress"
              placeholder="instance-weblinkportal.administrateweblink.com"
              onChange={value => changePortalAddress(value)}
            />
            <Checkbox
              description={t('builder:rememberPortalCheckbox')}
              name="rememberPortal"
              onChange={value => changeRememberPortal(value)}
            />
            <Submit
              label={t('builder:connectButton')}
              className="card-button-right"
            />
          </Form>
        </Card>
      </div>
    </>
  );
};

PortalSetup.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation()(
  inject(STORE_CONFIG, STORE_APOLLO)(observer(PortalSetup)),
);
