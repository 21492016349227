export const PRELOADER = `<span class="placeholder animated"></span>`;

/* source
@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}

.placeholder {
  border-radius: 4px;
  margin-top: 12px;
  margin-bottom: 12px;
  width: 100%;
  height: 1em;
  background: #f3f9fc;
  display: inline-block;
  background-size: 1000px 100%;
}

@media only screen and (min-width: 600px) {
  .placeholder {
    margin: 12px 20%;
    max-width: 60%;
  }
}

@media only screen and (min-width: 1200px) {
  .placeholder {
    margin: 12px 15%;
    max-width: 70%;
  }
}

.placeholder.animated {
  animation: shimmer 4s infinite linear;
  background-image: linear-gradient(
    to right,
    #f3f9fc 4%,
    #e7f3f9 25%,
    #f3f9fc 36%
  );
}
*/
export const PRELOADER_STYLES = `<style>@keyframes shimmer{0%{background-position:-1000px 0}100%{background-position:1000px 0}}.placeholder{border-radius:4px;margin-top:12px;margin-bottom:12px;width:100%;height:1em;background:#f3f9fc;display:inline-block;background-size:1000px 100%}@media only screen and (min-width: 600px){.placeholder{margin:12px 20%;max-width:60%}}@media only screen and (min-width: 1200px){.placeholder{margin:12px 15%;max-width:70%}}.placeholder.animated{animation:shimmer 4s infinite linear;background-image:linear-gradient( to right, #f3f9fc 4%, #e7f3f9 25%, #f3f9fc 36% )}</style>`;
