import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { ApolloProvider } from '@apollo/client';

import { STORES } from './constants';
import inject from './containers/inject';
import NotFound from './components/NotFound';
import Builder from './containers/Builder/Builder';
import PortalSetup from './containers/PortalSetup/PortalSetup';

const { STORE_APOLLO } = STORES;

const Router = ({
  [STORE_APOLLO]: { connectedPortalAddress, apolloClient },
}) => (
  <Switch>
    <Route exact path="/" component={PortalSetup} />
    <Route exact path="/builder">
      {connectedPortalAddress ? (
        <ApolloProvider client={apolloClient}>
          <Builder />
        </ApolloProvider>
      ) : (
        <Redirect to="/" />
      )}
    </Route>
    <Route component={NotFound} />
  </Switch>
);

export default inject(STORE_APOLLO)(observer(Router));
