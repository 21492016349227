import React from 'react';
import PropTypes from 'prop-types';
import { FieldGroup, Select, Input } from '@administrate/piston-ux';

const NumberWithOperationPicker = ({
  name,
  label,
  id,
  onOperationChange,
  onNumberValueChange,
}) => (
  <FieldGroup
    name={name}
    labelHidden={!label}
    label={label}
    describingId={id}
    childFlex={[5, 2]}
  >
    <Select
      name="operation"
      label="Operation"
      labelHidden
      options={[
        { value: 'eq', label: 'Equal to' },
        { value: 'ne', label: 'Not equal to' },
        { value: 'gt', label: 'Greater than' },
        { value: 'lt', label: 'Less than' },
        { value: 'ge', label: 'Greater than or equal to' },
        { value: 'le', label: 'Less than or equal to' },
      ]}
      placeholder="Select Operation..."
      onChange={onOperationChange}
    />
    <Input
      name="numberValue"
      label="Value"
      labelHidden
      type="number"
      min={0}
      onChange={value => {
        const valueOrNull = value === '' ? null : value;
        onNumberValueChange(valueOrNull);
      }}
    />
  </FieldGroup>
);

NumberWithOperationPicker.defaultProps = {
  label: null,
};

NumberWithOperationPicker.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  id: PropTypes.string.isRequired,
  onOperationChange: PropTypes.func.isRequired,
  onNumberValueChange: PropTypes.func.isRequired,
};

export default NumberWithOperationPicker;
