import { createBrowserHistory } from 'history';
import { createStores } from '@administrate/weblink';

const history = createBrowserHistory();

// eslint-disable-next-line import/no-mutable-exports
export let stores = null;

export default ({ apolloClient, portalAuthDomain }) => {
  stores = createStores({
    embededNavigation: true,
    history,
    apolloClient,
    callbacks: {
      navigateToCheckoutCallback: () => {
        const portalToken = window.localStorage.getItem(
          `weblink:${portalAuthDomain}:portalToken`,
        );
        const cartID = window.localStorage.getItem(
          `weblink:${portalAuthDomain}:cartId`,
        );
        window.location = `https://${portalAuthDomain}/checkout?portalToken=${portalToken}&cartID=${cartID}&originalSite=${window.location.origin}`;
      },
    },
  });
  return stores;
};
