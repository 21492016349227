import {
  RouterStore as BaseRouterStore,
  syncHistoryWithStore,
} from 'mobx-react-router';

export default class RouterStore extends BaseRouterStore {
  constructor(rootStore, history) {
    super();
    this.rootStore = rootStore;
    this.history = syncHistoryWithStore(history, this);
  }

  navigateToRoot = () => {
    this.push('/');
  };

  navigateToBuilder = () => {
    this.push('/builder');
  };
}
