import React from 'react';
import PropTypes from 'prop-types';
import { useApolloClient, gql } from '@apollo/client';
import { Typeahead } from '@administrate/piston-ux';
import { withTranslation } from 'react-i18next';
import { extractNodes } from './pickerHelpers';

const GET_LEARNING_CATEGORIES = gql`
  query ($filters: [CategoryFieldFilter]!, $order: CategoryFieldOrder) {
    categories(filters: $filters, order: $order) {
      edges {
        node {
          id
          name
          parent {
            id
            name
          }
        }
      }
    }
  }
`;

const formatCategoryName = category => {
  if (!category.parent) {
    return category.name;
  }

  return `${category.name} (${category.parent.name})`;
};

const CategoryPicker = ({ t, name, multiple, onChange }) => {
  const client = useApolloClient();

  const loadOptions = async inputValue => {
    const { data } = await client.query({
      query: GET_LEARNING_CATEGORIES,
      variables: {
        filters: [
          {
            field: 'name',
            operation: 'like',
            value: `%${inputValue}%`,
          },
        ],
        order: {
          field: 'name',
          direction: 'asc',
        },
      },
    });

    return extractNodes(data?.categories);
  };

  return (
    <Typeahead
      name={name}
      multiple={multiple}
      label={multiple ? t('builder:categories') : t('builder:category')}
      loadOptions={loadOptions}
      formatter={category => formatCategoryName(category)}
      staticFormatter={category => formatCategoryName(category)}
      onChange={onChange}
    />
  );
};

CategoryPicker.propTypes = {
  t: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  multiple: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

CategoryPicker.defaultProps = {
  multiple: false,
};

export default withTranslation()(CategoryPicker);
