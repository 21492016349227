import React from 'react';
import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Select } from '@administrate/piston-ux';

const WIDGETS = [
  { label: 'Basket', value: 'Basket' },
  {
    label: 'CallToActionCard',
    value: 'CallToActionCard',
  },
  { label: 'Cart', value: 'Cart' },
  { label: 'Catalogue', value: 'Catalogue' },
  { label: 'Category', value: 'Category' },
  {
    label: 'CategoryDropdown',
    value: 'CategoryDropdown',
  },
  { label: 'CourseDetails', value: 'CourseDetails' },
  { label: 'Debug', value: 'Debug' },
  { label: 'EventList', value: 'EventList' },
  { label: 'PathDetails', value: 'PathDetails' },
  { label: 'SearchBar', value: 'SearchBar' },
  { label: 'TrainingRequest', value: 'TrainingRequest' },
];

const WidgetPicker = ({ changeWidgets, t }) => (
  <Select
    name="widgets"
    multiple
    label={t('builder:widgetPickerLabel')}
    options={WIDGETS}
    onChange={selected => changeWidgets(selected)}
    placeholder={t('builder:searchPlaceholder')}
    helpMessage={t('builder:widgetPickerToolTip')}
  />
);

WidgetPicker.propTypes = {
  changeWidgets: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(observer(WidgetPicker));
