import { observer } from 'mobx-react-lite';
import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { useTypedFormValues, Form, Checkbox } from '@administrate/piston-ux';

const CartConfigForm = ({ t, onWidgetConfigChanged }) => {
  const values = useTypedFormValues({
    showPlacesRemaining: true,
  });

  return (
    <Form values={values}>
      <Checkbox
        name="showPlacesRemaining"
        description={t('builder:showPlacesRemaining')}
        onChange={value => onWidgetConfigChanged('showPlacesRemaining', value)}
      />
    </Form>
  );
};

CartConfigForm.propTypes = {
  t: PropTypes.func.isRequired,
  onWidgetConfigChanged: PropTypes.func.isRequired,
};

export default withTranslation()(observer(CartConfigForm));
