import React from 'react';
import PropTypes from 'prop-types';
import { Header } from '@administrate/piston-ux';

const PageHeader = ({ title, subtitle }) => (
  <Header title={title} subtitle={subtitle} usage="portal" />
);

PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
};

PageHeader.defaultProps = {
  subtitle: undefined,
};

export default PageHeader;
