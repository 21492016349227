import { observer } from 'mobx-react-lite';
import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Checkbox, Fieldset } from '@administrate/piston-ux';

export const EVENT_LIST_FILTER_INITIAL_FORM_VALUES = {
  showDateFilter: true,
  showLocationFilter: true,
};

const EventListFilterSelector = ({ t, onWidgetConfigChanged, children }) => (
  <Fieldset captionType="label" legend={t('builder:eventListFilters')}>
    {children}
    <Checkbox
      name="showDateFilter"
      description={t('builder:showDateFilterCheckbox')}
      onChange={value => onWidgetConfigChanged('showDateFilter', value)}
    />
    <Checkbox
      name="showLocationFilter"
      description={t('builder:showLocationFilterCheckbox')}
      onChange={value => onWidgetConfigChanged('showLocationFilter', value)}
    />
  </Fieldset>
);

EventListFilterSelector.defaultProps = {
  children: null,
};

EventListFilterSelector.propTypes = {
  t: PropTypes.func.isRequired,
  onWidgetConfigChanged: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default withTranslation()(observer(EventListFilterSelector));
