import React from 'react';
import { observer } from 'mobx-react-lite';
import {
  Alert,
  ArticleHeader,
  Card,
  Prompt,
  SectionHeader,
} from '@administrate/piston-ux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { STORES, PRELOADER } from '../../constants';
import inject from '../inject';
import ConfigForm from '../../components/ConfigForm/ConfigForm';
import PreWithCopy from '../../components/PreWithCopy/PreWithCopy';

const { STORE_CONFIG, STORE_PREVIEW } = STORES;

const WidgetList = ({
  [STORE_CONFIG]: { widgets, changePropOfWidget },
  [STORE_PREVIEW]: { openModal: openPreviewModal },
  t,
}) => (
  <div className="weblink-builder__widgets">
    <SectionHeader title={t('builder:widgetListTitle')} />
    {widgets.length !== 0 ? (
      <>
        <Alert message={t('builder:widgetListNotice')} type="info" />
        {widgets.map(widget => (
          <Card key={widget.id}>
            <ArticleHeader
              title={widget.name}
              titleLevel="h3"
              create={{
                onClick: () => openPreviewModal(widget),
                label: t('builder:previewButton'),
              }}
            />
            <ConfigForm
              widget={widget}
              changePropOfWidget={changePropOfWidget}
            />
            <PreWithCopy
              disabled={!widget.isValid}
              content={`<div id="${widget.id}">\n  ${PRELOADER}\n</div>`}
            />
          </Card>
        ))}
      </>
    ) : (
      <Prompt message={t('builder:widgetListEmptyNotice')} type="info" />
    )}
  </div>
);

WidgetList.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation()(
  inject(STORE_CONFIG, STORE_PREVIEW)(observer(WidgetList)),
);
