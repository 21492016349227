import { observable, decorate, runInAction, action, reaction } from 'mobx';
import dedent from 'dedent';
import { _bundleConfig as config } from '@administrate/weblink';

import BaseStore from './baseStore';
import Widget from './Widget';
import { PRELOADER_STYLES } from '../constants';

export default class ConfigStore extends BaseStore {
  initialize = action(() => {
    this.builtCode = null;
    this.hashRouting = false;
    this.widgets = [];
  });

  constructor(rootStore) {
    super(rootStore);

    runInAction(() => {
      this.rememberPortal = !!window.localStorage.getItem(
        'weblink:portalAddress',
      );
      this.portalAddress = window.localStorage.getItem('weblink:portalAddress');
      this.timezone =
        window.localStorage.getItem('weblink-builder:timezone') ?? null;
    });

    this.initialize();

    reaction(
      () => ({
        portalAddress: this.portalAddress,
        hashRouting: this.hashRouting,
        timezone: this.timezone,
        widgets: this.widgets,
        widgetsLength: this.widgets.length,
      }),
      () => {
        if (this.builtCode) {
          this.buildCode();
        }
      },
      { delay: 1000 },
    );
  }

  changeTimezone = action(value => {
    this.timezone = value;
    window.localStorage.setItem('weblink-builder:timezone', value);
  });

  changeHashRouting = action(value => {
    this.hashRouting = value;
    Object.assign(config, { hashRouting: value });
  });

  changePortalAddress = action(value => {
    this.initialize();
    this.portalAddress = value;
  });

  changeRememberPortal = action(value => {
    this.rememberPortal = value;
  });

  changeWidgets = action(widgets => {
    const getProps = ({ id, name }) => {
      const widget = this.widgets.find(w => w.id === id);
      if (widget) {
        return widget.props;
      }
      if (name === 'Catalogue') {
        return {
          showDateFilter: true,
          showLocationFilter: true,
          showCourseFilter: true,
          showCategoryFilter: true,
          showTitleColumn: false,
          showLocationColumn: true,
          showVenueColumn: false,
          showStartDateColumn: true,
          showEndDateColumn: false,
          showDateAndTimeColumn: false,
          showDurationColumn: true,
          showSessionsColumn: true,
          showTimeColumn: true,
          showPlacesRemainingColumn: true,
          showPriceColumn: true,
          showAddToCartColumn: true,
          showClassroomStartDateColumn: false,
          showClassroomDurationColumn: false,
          showClassroomTimeColumn: false,
          showLmsStartDateColumn: false,
          showLmsDurationColumn: false,
          showLmsTimeColumn: false,
          pagerType: 'loadMore',
          pageSize: 6,
          catalogueCardOptions: {
            showDescription: false,
            showCategory: true,
            showFeaturedLabel: true,
            showPrice: true,
          },
        };
      }
      if (name === 'CourseDetails' || name === 'Category') {
        return {
          showDateFilter: true,
          showLocationFilter: true,
          showCourseFilter: true,
          showCategoryFilter: true,
          showTitleColumn: false,
          showLocationColumn: true,
          showVenueColumn: false,
          showStartDateColumn: true,
          showEndDateColumn: false,
          showDateAndTimeColumn: false,
          showDurationColumn: true,
          showSessionsColumn: true,
          showTimeColumn: true,
          showPlacesRemainingColumn: true,
          showPriceColumn: true,
          showAddToCartColumn: true,
          showClassroomStartDateColumn: false,
          showClassroomDurationColumn: false,
          showClassroomTimeColumn: false,
          showLmsStartDateColumn: false,
          showLmsDurationColumn: false,
          showLmsTimeColumn: false,
        };
      }
      if (name === 'EventList') {
        return {
          showDateFilter: true,
          showLocationFilter: true,
          showCourseFilter: true,
          showCategoryFilter: true,
          showTitleColumn: true,
          showLocationColumn: true,
          showVenueColumn: false,
          showStartDateColumn: true,
          showEndDateColumn: false,
          showDateAndTimeColumn: false,
          showDurationColumn: true,
          showSessionsColumn: true,
          showTimeColumn: true,
          showPlacesRemainingColumn: true,
          showPriceColumn: true,
          showAddToCartColumn: true,
          showClassroomStartDateColumn: false,
          showClassroomDurationColumn: false,
          showClassroomTimeColumn: false,
          showLmsStartDateColumn: false,
          showLmsDurationColumn: false,
          showLmsTimeColumn: false,
        };
      }
      if (name === 'Basket') {
        return {
          showBasketPopover: false,
        };
      }
      if (name === 'Cart') {
        return {
          showPlacesRemaining: true,
        };
      }
      return {};
    };

    this.widgets = widgets.map(name => {
      const id = `weblink-${name}`;
      return new Widget({
        id,
        name,
        props: getProps({ id, name }),
      });
    });
  });

  changePropOfWidget = action(({ propKey, propValue, id }) => {
    const widgetIndex = this.widgets.findIndex(widget => widget.id === id);
    this.widgets[widgetIndex].changeProps({ propKey, propValue });
    // We cannot react to changes on props of an object of an obserbale array - rebuild "manually"
    if (this.builtCode) {
      this.buildCode();
    }
  });

  buildCode = action(() => {
    if (this.portalAddress && this.timezone) {
      this.builtCode = dedent`
        <!-- Styling  -->
        ${PRELOADER_STYLES}
        <link
          rel="stylesheet"
          href="https://${this.portalAddress}/static/css/main.css"
        />
        <!-- JQuery - you can skip this line if you already have JQuery in your app -->
        <script src="http://ajax.googleapis.com/ajax/libs/jquery/1.11.0/jquery.min.js"></script>
        <!-- Weblink-->
        <script src="https://${
          this.portalAddress
        }/static/js/weblink.bundle.min.js" defer ></script>
        <!-- Create Weblink instance and mount widgets-->
        <script>
          $(function () {
            const webLinkConfig = {
              portalAddress: '${this.portalAddress}',
              hashRouting: ${this.hashRouting},
              timezone: '${this.timezone}',
              hooks: {
                onCheckoutNavigation: function(redirectUrl) {
                  // ... perform whatever actions necessary here

                  // Returning false below will prevent the usual redirect to
                  // the checkout page to happen. If you want to redirect there
                  // after you have completed your actions, you can use the
                  // redirectUrl provided to the function.
                  // return false;
                },
              },
              analyticsHandler: function (event) {
                // Send event to the analytics provider.
                // For list of tracked events and examples, [see our Documentation](https://developer.getadministrate.com/docs/weblink/Widgets/04_analytics.md)

                // For example, if using Google Tag Manager,
                //   1. Ensure snippet is added to your website according to the [instructions](https://developers.google.com/tag-platform/tag-manager/web)
                //   2. Then call: window.dataLayer.push(event)
              }
            };

            const webLink = new window.WebLink(webLinkConfig);
            ${this.widgets
              .map(
                ({ id, name, props }) => `
            webLink.mount(
              document.getElementById('${id}'),
              '${name}'${
                  Object.keys(props).length
                    ? `,
              ${JSON.stringify(props)}`
                    : ''
                }
            );`,
              )
              .join('')}
          });
        </script>
      `;
    }
  });

  logConfiguration = () => {
    try {
      fetch('/structuredLog', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          category: 'configuration',
          subcategory: 'built',
          details: {
            portalAddress: this.portalAddress,
            widgets: this.widgets,
          },
        }),
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn('Error creating structured log: ', e);
    }
  };
}

decorate(ConfigStore, {
  portalAddress: observable,
  rememberPortal: observable,
  widgets: observable,
  timezone: observable,
  hashRouting: observable,
  builtCode: observable,
});
