import { observable, decorate, runInAction, action } from 'mobx';

export default class PreviewStore {
  constructor() {
    runInAction(() => {
      this.widget = null;
      this.show = null;
    });
  }

  openModal = action(widget => {
    this.widget = widget;
    this.show = true;
  });

  closeModal = action(() => {
    this.show = false;
    this.widget = null;
  });
}

decorate(PreviewStore, {
  show: observable,
  widget: observable,
});
