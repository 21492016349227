import { observer } from 'mobx-react-lite';
import React from 'react';
import PropTypes from 'prop-types';
import { useTypedFormValues, Form } from '@administrate/piston-ux';
import EventListFilterSelector, {
  EVENT_LIST_FILTER_INITIAL_FORM_VALUES,
} from './shared/EventListFilterSelector';
import EventListColumnSelector, {
  EVENT_LIST_COLUMN_INITIAL_FORM_VALUES,
} from './shared/EventListColumnSelector';
import CoursePicker from '../pickers/CoursePicker';

const CourseDetailsConfigForm = ({ onWidgetConfigChanged }) => {
  const values = useTypedFormValues({
    course: null,
    ...EVENT_LIST_FILTER_INITIAL_FORM_VALUES,
    ...EVENT_LIST_COLUMN_INITIAL_FORM_VALUES,
  });

  return (
    <Form values={values}>
      <CoursePicker
        name="course"
        required
        valid={v => !!v}
        onChange={course => onWidgetConfigChanged('code', course.code)}
      />
      <EventListFilterSelector onWidgetConfigChanged={onWidgetConfigChanged} />
      <EventListColumnSelector onWidgetConfigChanged={onWidgetConfigChanged} />
    </Form>
  );
};

CourseDetailsConfigForm.propTypes = {
  onWidgetConfigChanged: PropTypes.func.isRequired,
};

export default observer(CourseDetailsConfigForm);
