import { observable, decorate, runInAction, action, computed } from 'mobx';

export default class Widget {
  constructor({ id = null, props = {}, name = null }) {
    runInAction(() => {
      this.id = id;
      this.props = props;
      this.name = name;
    });
  }

  changeProps = action(({ propKey, propValue }) => {
    const newProps = Object.assign({}, this.props, { [propKey]: propValue });
    this.props = newProps;
  });

  get isValid() {
    switch (this.name) {
      case 'PathDetails':
        return !!this.props.id;
      case 'CourseDetails':
        return !!this.props.code;
      case 'CallToActionCard':
        return !this.props.courseCode !== !this.props.learningPathId; // XOR
      default:
        return true;
    }
  }
}

decorate(Widget, {
  id: observable,
  props: observable,
  name: observable,
  isValid: computed,
});
