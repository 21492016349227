import React from 'react';
import PropTypes from 'prop-types';
import { PropTypes as MobXPropTypes } from 'mobx-react';
import {
  Input,
  RadioButtons,
  Select,
  UtilityStyle,
} from '@administrate/piston-ux';
import { withTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import _uniqBy from 'lodash/uniqBy';
import { toJS } from 'mobx';

const buildMergedOptionsFromMappedCustomFields = mappedCustomFields =>
  _uniqBy(
    mappedCustomFields.flatMap(mappedCustomField => mappedCustomField.options),
    option => option.value,
  );

export const getDefaultValueForCustomFieldFilterType = type => {
  switch (type) {
    case 'multichoice':
      return [];

    case 'text':
      return '';

    case 'checkbox':
      return true;

    default:
      return null;
  }
};

const multichoiceCustomFieldConfigurationFormatter = (id, value) => ({
  id,
  operation: 'in',
  values: value ?? [],
});

const textCustomFieldConfigurationFormatter = (id, value) => ({
  id,
  operation: 'like',
  value: `%${value}%`,
});

const checkboxCustomFieldConfigurationFormatter = (id, value) => ({
  id,
  operation: 'eq',
  value: value.toString(),
});

export const getCustomFieldConfigurationFormatter = type => {
  switch (type) {
    case 'multichoice':
      return multichoiceCustomFieldConfigurationFormatter;

    case 'text':
      return textCustomFieldConfigurationFormatter;

    case 'checkbox':
      return checkboxCustomFieldConfigurationFormatter;

    default:
      return () => null;
  }
};

const CustomFieldFilterValuePicker = ({
  t,
  name,
  customFieldFilter,
  onChange,
}) => {
  const customFieldConfigurationFormatter =
    getCustomFieldConfigurationFormatter(customFieldFilter?.type);

  const onValueChange = value =>
    onChange(customFieldConfigurationFormatter(customFieldFilter?.id, value));

  switch (customFieldFilter?.type) {
    case 'multichoice': {
      const options = buildMergedOptionsFromMappedCustomFields(
        toJS(customFieldFilter.mappedCustomFields),
      );
      return (
        <MultichoiceValuePicker
          t={t}
          name={name}
          options={options}
          onChange={onValueChange}
        />
      );
    }

    case 'text':
      return <TextValuePicker t={t} name={name} onChange={onValueChange} />;

    case 'checkbox':
      return <CheckboxValuePicker t={t} name={name} onChange={onValueChange} />;

    default:
      return (
        <Input type="text" label={t('builder:value')} name={name} disabled />
      );
  }
};

CustomFieldFilterValuePicker.propTypes = {
  t: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  customFieldFilter: PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    mappedCustomFields: MobXPropTypes.observableArrayOf(
      MobXPropTypes.observableObject,
    ).isRequired,
  }),
  onChange: PropTypes.func.isRequired,
};

CustomFieldFilterValuePicker.defaultProps = {
  customFieldFilter: null,
};

const MultichoiceValuePicker = ({ t, name, options, onChange }) => (
  <Select
    name={name}
    multiple
    label={t('builder:value')}
    options={options}
    onChange={onChange}
    helpMessage={t('builder:multichoiceCustomFieldFilterHelpMessage')}
  />
);

MultichoiceValuePicker.propTypes = {
  t: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onChange: PropTypes.func.isRequired,
};

const TextValuePicker = ({ t, name, onChange }) => (
  <Input
    name={name}
    label={t('builder:value')}
    type="text"
    onChange={onChange}
    helpMessage={t('builder:textCustomFieldFilterHelpMessage')}
  />
);

TextValuePicker.propTypes = {
  t: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const CheckboxValuePicker = ({ t, name, onChange }) => (
  <RadioButtons
    styleOptions={[UtilityStyle.FontBold]}
    name={name}
    label={t('builder:value')}
    onChange={onChange}
    options={[
      {
        label: t('builder:yes'),
        value: true,
      },
      {
        label: t('builder:no'),
        value: false,
      },
    ]}
    type="button"
    helpMessage={t('builder:checkboxCustomFieldFilterHelpMessage')}
  />
);

CheckboxValuePicker.propTypes = {
  t: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default withTranslation()(observer(CustomFieldFilterValuePicker));
