import React from 'react';
import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import {
  Card,
  Checkbox,
  Form,
  Prompt,
  SectionHeader,
  Select,
  Submit,
  useTypedFormValues,
} from '@administrate/piston-ux';

import { STORES } from '../../constants';
import inject from '../inject';
import WidgetPicker from '../../components/WidgetPicker/WidgetPicker';
import PreWithCopy from '../../components/PreWithCopy/PreWithCopy';
import { TIMEZONE_OPTIONS } from '../../constants/timezones';

const { STORE_CONFIG } = STORES;

const WeblinkConfig = ({
  [STORE_CONFIG]: {
    portalAddress,
    builtCode,
    buildCode,
    logConfiguration,
    changeWidgets,
    timezone,
    changeTimezone,
    changeHashRouting,
    widgets,
  },
  t,
}) => {
  const formValues = useTypedFormValues({
    timezone,
    hashRouting: false,
    widgets: [],
  });

  return (
    <Card>
      <SectionHeader title={t('builder:weblinkConfig')} />
      <Form
        values={formValues}
        onSubmit={() => {
          buildCode();
          logConfiguration();
        }}
      >
        <Select
          name="timezone"
          label={t('builder:timezone')}
          placeholder={t('builder:searchPlaceholder')}
          valid={updatedTimezone =>
            !!updatedTimezone || t('builder:timezoneIsRequired')
          }
          options={TIMEZONE_OPTIONS.map(name => ({
            label: name,
            value: name,
          }))}
          onChange={selected => changeTimezone(selected)}
        />
        <Checkbox
          description={t('builder:hashRoutingLabel')}
          name="hashRouting"
          helpMessage={t('builder:hashRoutingToolTip')}
          onChange={value => changeHashRouting(value)}
        />
        <WidgetPicker changeWidgets={changeWidgets} widgets={widgets} />
        <Submit
          label={t('builder:buildButton')}
          className="card-button-right"
          disabled={!portalAddress}
        />
        {builtCode && (
          <>
            <Prompt type="info" message={t('builder:builtCodeNotice')} />
            <PreWithCopy
              disabled={!builtCode}
              content={builtCode}
              className="built-code"
            />
          </>
        )}
      </Form>
    </Card>
  );
};

WeblinkConfig.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation()(inject(STORE_CONFIG)(observer(WeblinkConfig)));
