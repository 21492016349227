/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';

import CatalogueConfigForm from './CatalogueConfigForm';
import EventListConfigForm from './EventListConfigForm';
import CartConfigForm from './CartConfigForm';
import BasketConfigForm from './BasketConfigForm';
import CategoryConfigForm from './CategoryConfigForm';
import CourseDetailsConfigForm from './CourseDetailsConfigForm';
import PathDetailsConfigForm from './PathDetailsConfigForm';
import CallToActionCardConfigForm from './CallToActionCardConfigForm';
import TrainingRequestConfigForm from './TrainingRequestConfigForm';

const ConfigForm = ({ widget, changePropOfWidget }) => {
  const updateWidgetConfiguration = (propKey, propValue) =>
    changePropOfWidget({
      propKey,
      propValue,
      id: widget.id,
    });

  switch (widget.name) {
    case 'CourseDetails': {
      return (
        <CourseDetailsConfigForm
          widget={widget}
          onWidgetConfigChanged={updateWidgetConfiguration}
        />
      );
    }
    case 'PathDetails': {
      return (
        <PathDetailsConfigForm
          onWidgetConfigChanged={updateWidgetConfiguration}
        />
      );
    }
    case 'EventList': {
      return (
        <EventListConfigForm
          widget={widget}
          onWidgetConfigChanged={updateWidgetConfiguration}
        />
      );
    }
    case 'Catalogue':
      return (
        <CatalogueConfigForm
          widget={widget}
          onWidgetConfigChanged={updateWidgetConfiguration}
        />
      );
    case 'Category':
      return (
        <CategoryConfigForm
          widget={widget}
          onWidgetConfigChanged={updateWidgetConfiguration}
        />
      );
    case 'Basket':
      return (
        <BasketConfigForm onWidgetConfigChanged={updateWidgetConfiguration} />
      );
    case 'CallToActionCard':
      return (
        <CallToActionCardConfigForm
          onWidgetConfigChanged={updateWidgetConfiguration}
        />
      );
    case 'Cart':
      return (
        <CartConfigForm onWidgetConfigChanged={updateWidgetConfiguration} />
      );
    case 'TrainingRequest':
      return (
        <TrainingRequestConfigForm
          onWidgetConfigChanged={updateWidgetConfiguration}
        />
      );
    default:
      return null;
  }
};

ConfigForm.propTypes = {
  changePropOfWidget: PropTypes.func.isRequired,
  widget: PropTypes.object.isRequired,
};

export default observer(ConfigForm);
