import { observer } from 'mobx-react-lite';
import React from 'react';
import PropTypes from 'prop-types';
import { useTypedFormValues, Form } from '@administrate/piston-ux';
import EventListFilterSelector, {
  EVENT_LIST_FILTER_INITIAL_FORM_VALUES,
} from './shared/EventListFilterSelector';
import EventListColumnSelector, {
  EVENT_LIST_COLUMN_INITIAL_FORM_VALUES,
} from './shared/EventListColumnSelector';

const CategoryConfigForm = ({ onWidgetConfigChanged }) => {
  const values = useTypedFormValues({
    ...EVENT_LIST_FILTER_INITIAL_FORM_VALUES,
    ...EVENT_LIST_COLUMN_INITIAL_FORM_VALUES,
  });

  return (
    <Form values={values}>
      <EventListFilterSelector onWidgetConfigChanged={onWidgetConfigChanged} />
      <EventListColumnSelector onWidgetConfigChanged={onWidgetConfigChanged} />
    </Form>
  );
};

CategoryConfigForm.propTypes = {
  onWidgetConfigChanged: PropTypes.func.isRequired,
};

export default observer(CategoryConfigForm);
