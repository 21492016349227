import { Observer, observer } from 'mobx-react-lite';
import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import {
  useTypedFormValues,
  Form,
  RadioButtons,
} from '@administrate/piston-ux';
import CoursePicker from '../pickers/CoursePicker';
import LearningPathPicker from '../pickers/LearningPathPicker';

const CallToActionCardConfigForm = ({ t, onWidgetConfigChanged }) => {
  const values = useTypedFormValues({
    type: 'course',
    course: null,
    learningPath: null,
  });

  const resetSelectedProduct = () => {
    values.course = null;
    values.learningPath = null;

    onWidgetConfigChanged('courseCode', undefined);
    onWidgetConfigChanged('learningPathId', undefined);
  };

  return (
    <Form values={values}>
      <RadioButtons
        name="type"
        options={[
          {
            label: t('builder:course'),
            value: 'course',
          },
          {
            label: t('builder:path'),
            value: 'learningPath',
          },
        ]}
        type="button"
        onChange={resetSelectedProduct}
      />
      <Observer>
        {() =>
          values.type === 'course' ? (
            <CoursePicker
              name="course"
              required={values.type === 'course'}
              onChange={course =>
                onWidgetConfigChanged('courseCode', course.code)
              }
            />
          ) : (
            <LearningPathPicker
              name="learningPath"
              required={values.type === 'learningPath'}
              onChange={learningPath =>
                onWidgetConfigChanged('learningPathId', learningPath.id)
              }
            />
          )
        }
      </Observer>
    </Form>
  );
};

CallToActionCardConfigForm.propTypes = {
  t: PropTypes.func.isRequired,
  onWidgetConfigChanged: PropTypes.func.isRequired,
};

export default withTranslation()(observer(CallToActionCardConfigForm));
