import { Button } from '@administrate/piston-ux';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { PrismLight as SyntaxHighlighter } from 'react-syntax-highlighter';
import markup from 'react-syntax-highlighter/dist/cjs/languages/prism/markup';
import { coldarkCold as style } from 'react-syntax-highlighter/dist/cjs/styles/prism';

SyntaxHighlighter.registerLanguage('markup', markup);

const PreWithCopy = ({ disabled, content, className, t }) => (
  <div className="PreWithCopy">
    <Button
      onClick={() => {
        const copyArea = document.createElement('textarea');
        copyArea.value = content;
        document.body.appendChild(copyArea);
        copyArea.select();
        document.execCommand('copy');
        document.body.removeChild(copyArea);
      }}
      label={t('builder:copyButton')}
      disabled={disabled}
      type="primary"
    />
    <SyntaxHighlighter language="markup" style={style} className={className}>
      {content}
    </SyntaxHighlighter>
  </div>
);

PreWithCopy.defaultProps = {
  className: '',
};

PreWithCopy.propTypes = {
  disabled: PropTypes.bool.isRequired,
  content: PropTypes.string.isRequired,
  className: PropTypes.string,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(PreWithCopy);
