import React, { Suspense } from 'react';
import { Router as ReactRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import Router from './Router';
import { configure as configureI18n } from './i18n';
import { Provider as ContextProvider } from './contexts';

const i18n = configureI18n();

const WeblinkBuilder = ({ stores, history }) => (
  <ContextProvider stores={stores} i18n={i18n}>
    <Suspense fallback="Loading...">
      <ReactRouter history={history}>
        <Router />
      </ReactRouter>
    </Suspense>
  </ContextProvider>
);

WeblinkBuilder.propTypes = {
  stores: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({}).isRequired,
};

export default WeblinkBuilder;
