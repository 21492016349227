import momentTimezone from 'moment-timezone';

const invalidTimezoneNames = [
  'CET',
  'CST6CDT',
  'EST',
  'EET',
  'EST5EDT',
  'GB-Eire',
  'GB',
  'GMT+0',
  'GMT-0',
  'GMT0',
  'HST',
  'MET',
  'MST',
  'MST7MDT',
  'NZ',
  'NZ-CHAT',
  'PRC',
  'PST8PDT',
  'ROK',
  'ROC',
  'UCT',
  'W-SU',
  'WET',
];

const checkIfTimezoneIsValid = timezoneName =>
  !timezoneName.startsWith('US/') &&
  !invalidTimezoneNames.includes(timezoneName);

// The moment-timezone library only offers one way to get a list of all the timezone names, used below.
// This list includes timezone names which are deprecated and not supported in Chrome, such as 'US/Eastern'.
// So we filter out the ones that we know definitely aren't supported. The invalid timezone list is not
// exhaustive and only contains the timezones we are aware of that aren't supported.
// https://github.com/moment/moment-timezone/issues/499
export const TIMEZONE_OPTIONS = momentTimezone.tz
  .names()
  .filter(checkIfTimezoneIsValid);
